import React, { Component } from "react";
import "./static/index.css";
import axios from "axios";
import { DateRangePickerComponent, DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import "./static/material.css";
import { SliderComponent, ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import $ from "jquery";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import "./react-inputs/material.css";
import "./react-inputs/quick-modal.min.css";

import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import ClipLoader from "react-spinners/ClipLoader";

import AvatarEditor from "react-avatar-editor";
import { createElement } from "@syncfusion/ej2-base";
import { generateItems } from "./utils";
import PropTypes from "prop-types";
import MyTimeline from "./components/MyTimeline";
import html2pdf from "html2pdf.js"
import { API_URL } from "./constants";

const NewStar = ({ willBeActive, isActive }) => {
  const color = isActive ? "#009688" : willBeActive ? "#009688" : "#E3E3E3";
  return <i className="fa fa-star" style={{ fontSize: 14, color: color }} aria-hidden="true" />;
};

NewStar.propTypes = {
  willBeActive: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default class resume extends Component {
  pdfExportComponent;
  userData;

  constructor(props) {
    super(props);
    this.circlePaletteColors = {
      custom: ["#f44336", "#673ab7", "#009688", "#000"],
    };
    this.dataLoadingComplete = false
    this.requestedAction = props.location.action
    this.state = {
      activeFont: "applesystem",
      auth: localStorage.getItem("email"),
      trainings: [""],
      placeholder: [""],
      projectname: [""],
      projectdate: [],
      projectsum: [],

      summary: "",
      percnt: [""],
      progress: ["0"],
      workexpr: [""],
      workexpr1: [],
      workexpr2: [],
      workexpr3: [],
      workexpr4: [],
      workexpr5: [],
      education: [""],
      education1: [],
      education2: [],
      education3: [],
      education4: [],
      education5: [],
      file: [],
      value: 10,
      title: [],
      langname: [""],
      rating: [1],
      to: ["Beginner"],
      myTimeChartInformation: [
        {
          proportion: 50,
          skill: "",
        },
        {
          proportion: 50,
          skill: "",
        }
      ],
      loading: [false, "none"],
      editor: null,
      zoom: 1,
      socialmedia: [],
      name: [],
      color: "",
      pshorttitle: [],
      items: generateItems(5, (index) => {
        return {
          id: index,
          data: index,
        };
      }),
    };

    this.tooltip = { placement: "Before", isVisible: true, showOn: "Active" };
    this.ticks = { placement: "Before", largeStep: 0 };
    this.tooltipplacement = [
      { text: "Before", value: "Before" },
      { text: "After", value: "After" },
    ];
    this.ticksplacement = [
      { text: "Before", value: "Before" },
      { text: "After", value: "After" },
      { text: "Both", value: "Both" },
      { text: "None", value: "None" },
    ];

    this.position = { X: "Right" };
    this.toasts = [
      {
        title: "Warning!",
        content: "Maximum 700 Characters Only Accept.",
        cssClass: "e-toast-warning",
        icon: "e-warning toast-icons",
      },
      {
        content: "Saving.",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
      {
        title: "Error!",
        content: "A problem has been occurred while submitting your data.",
        cssClass: "e-toast-danger",
        icon: "e-error toast-icons",
      },
      {
        title: "Information!",
        content: "Please read the comments carefully.",
        cssClass: "e-toast-info",
        icon: "e-info toast-icons",
      },
    ];
    this.placeCaretAtEnd = this.placeCaretAtEnd.bind(this);

    this.start = "Year";
    this.depth = "Year";
  }
  tooltipChangeHandler(args) {
    // Splitting the range values from the tooltip using space into an array.

    args.text = Number(args.text) + " %";
  }

  componentWillUnmount(e, event) {
    this.insertdb(this.state, true);
  }

  componentDidMount(e, event) {
    var auth1 = this.state.auth;
    if (auth1 === null || auth1 === "undefined") {
      document.getElementById("login").style.display = "block";
      document.getElementById("signup").style.display = "block";
      document.getElementById("logout").style.display = "none";
      document.getElementById("pdf").style.display = "none";
      document.getElementById("updf").style.display = "block";

      document.getElementById("color").style.display = "block";
      document.getElementById("home").style.display = "block";
      if (localStorage.getItem("login") === null) {
        localStorage.setItem("login", "yes");
        window.location.href = "/";
      } else {
        localStorage.removeItem("login");
      }

      // e.preventDefault();
    } else {
      document.getElementById("color").style.display = "block";
      document.getElementById("signup").style.display = "none";
      document.getElementById("logout").style.display = "block";
      document.getElementById("pdf").style.display = "block";
      document.getElementById("updf").style.display = "none";

      document.getElementById("home").style.display = "none";
    }

    if (localStorage.getItem("email") === null) {
      if (localStorage.getItem("store")) {
        this.trains = localStorage.getItem("trainings").split(",");
        this.place = localStorage.getItem("placeholder").split(",");
        this.skl = localStorage.getItem("percnt").split(",");
        this.pcnt = localStorage.getItem("progress").split(",");
        this.pname = localStorage.getItem("projectname").split(",");
        this.pdate = localStorage.getItem("projectdate").split(",");
        this.psum = localStorage.getItem("projectsum").split(",");
        this.rol = localStorage.getItem("workexpr").split(",");
        this.cname = localStorage.getItem("workexpr1").split(",");
        this.clocation = localStorage.getItem("workexpr2").split(",");
        this.workdate = localStorage.getItem("workexpr4").split(",");
        this.esum = localStorage.getItem("workexpr5").split(",");
        this.edu1 = localStorage.getItem("education").split(",");
        this.edu2 = localStorage.getItem("education1").split(",");

        this.edudate = localStorage.getItem("education2").split(",");
        this.eduloc = localStorage.getItem("education4").split(",");
        this.educgpa = localStorage.getItem("education5").split(",");
        this.title1 = localStorage.getItem("title").split(",");
        this.sum = localStorage.getItem("summary").split(",");
        this.fnm = localStorage.getItem("img");
        this.name = localStorage.getItem("name").split(",");
        this.fn = this.name[0];
        this.ln = this.name[1];
        this.rl = this.name[2];
        this.color = localStorage.getItem("color");
        this.shorttitle1 = localStorage.getItem("pshorttitle").split(",");
        this.socialmedia = localStorage.getItem("socialmedia").split(",");
        this.em = this.socialmedia[0];
        this.pn = this.socialmedia[1];
        this.lk = this.socialmedia[2];
        this.address = this.socialmedia[3];
        this.lked = this.socialmedia[4];
        this.lang_name = localStorage.getItem("langname").split(",");
        this.rating = localStorage.getItem("rating").split(",");
        this.level = localStorage.getItem("to").split(",");

        this.trains = this.trains !== "" ? this.trains : [""];
        this.place = this.place !== "" ? this.place : ["Certifications"];
        let myTimeChartInformation = JSON.parse(localStorage.getItem("myTimeChartInformation")) || [];
        if (myTimeChartInformation.length === 0) {
          myTimeChartInformation = [
            {
              proportion: 1,
              skill: "",
            },
            {
              proportion: 1,
              skill: "",
            },
          ];
        }
        if (this.lk) {
          document.getElementsByClassName('lik1')[0].removeAttribute("data-html2canvas-ignore");
        }
        if (this.trains[0]) {
          document.getElementsByClassName('tc')[0].removeAttribute("data-html2canvas-ignore");
        }
        // if (this.educgpa) {
        //   document.getElementsByClassName('pg')[0].removeAttribute("data-html2canvas-ignore");
        // }

        this.setState({
          trainings: this.trains,
          placeholder: this.place,
          percnt: this.skl,
          progress: this.pcnt,
          projectname: this.pname,
          projectsum: this.psum,
          pshorttitle: this.shorttitle1,
          projectdate: this.pdate,
          workexpr: this.rol,
          workexpr1: this.cname,
          workexpr2: this.clocation,
          workexpr4: this.workdate,
          workexpr5: this.esum,
          education: this.edu1,
          education1: this.edu2,
          education2: this.edudate,
          education4: this.eduloc,
          education5: this.educgpa,
          summary: this.sum,
          name: this.name,
          socialmedia: this.socialmedia,
          title: this.title1,
          langname: this.lang_name,
          rating: this.rating,
          to: this.level,
          color: this.color,
          img: "",
          myTimeChartInformation: myTimeChartInformation,
        }, function () {
          this.dataLoadingComplete = true
        });
      } else {
        this.trains = [""];
        this.place = ["Certifications"];
        this.title1 = ["SUMMARY", "EDUCATION", "CERTIFICATION", "PROFESSIONAL  SKILLS", "work experience", "PROJECTS", "LANGUAGE"];
        if (this.lk) {
          document.getElementsByClassName('lik1')[0].removeAttribute("data-html2canvas-ignore");
        }
        if (this.trains[0]) {
          document.getElementsByClassName('tc')[0].removeAttribute("data-html2canvas-ignore");
        }
        // if (this.educgpa) {
        //   document.getElementsByClassName('pg')[0].removeAttribute("data-html2canvas-ignore");
        // }
        this.setState({
          trainings: this.trains,
          placeholder: this.place,
          title: this.title1,
        }, function () {
          this.dataLoadingComplete = true
        });
      }
    } else {
      axios({
        method: "get",
        url: API_URL + "/sign.php?resume1=" + auth1,
        // headers: { "Access-Control-Allow-Origin": "*" },
      })
        .then((res) => res.data)
        .then((data) => {
          if (data[0] !== null) {
            this.trains = data[0].trainings.split(",");
            this.place = data[0].placeholder.split(",");
            this.skl = data[0].skill.split(",");
            this.pcnt = data[0].percent.split(",");
            this.pname = data[0].projectname.split(",");
            this.pdate = data[0].projectdate.split(",");
            this.psum = data[0].projectsum.split(",");
            this.rol = data[0].role.split(",");
            this.cname = data[0].cname.split(",");
            this.clocation = data[0].clocation.split(",");
            this.workdate = data[0].workdate.split(",");
            this.esum = data[0].educationsum.split(",");
            this.edu1 = data[0].eduname1.split(",");
            this.edu2 = data[0].eduname2.split(",");
            this.edudate = data[0].edudate.split(",");
            this.eduloc = data[0].eduloc.split(",");
            this.educgpa = data[0].educgpa.split(",");
            this.title1 = data[0].title.split(",");
            this.sum = data[0].summary;
            this.fnm = data[0].image ? "./images/" + data[0].image : "undefined";
            this.name = [data[0].fname, data[0].lname, data[0].roles];
            this.fn = data[0].fname;
            this.ln = data[0].lname;
            this.rl = data[0].roles;
            this.color = data[0].color;
            this.shorttitle1 = data[0].projectshorttitle.split(",");

            this.socialmedia = [data[0].email, data[0].phone, data[0].website, data[0].address, data[0].link];
            this.em = data[0].email;
            this.pn = data[0].phone;
            this.lk = data[0].website;
            this.address = data[0].address;
            this.lked = data[0].link;
            this.lang_name = data[0].langname.split(",");
            this.rating = data[0].rating.split(",");
            this.level = data[0].level.split(",");
            // console.log(this.trains)

            this.trains = this.trains !== "" ? this.trains : [""];
            this.place = this.place !== "" ? this.place : ["Certifications"];
            if (this.lk) {
              document.getElementsByClassName('lik1')[0].removeAttribute("data-html2canvas-ignore");
            }
            if (this.trains[0]) {
              document.getElementsByClassName('tc')[0].removeAttribute("data-html2canvas-ignore");
            }
            // if (this.educgpa) {
            //   document.getElementsByClassName('pg')[0].removeAttribute("data-html2canvas-ignore");
            // }
            this.setState({
              trainings: this.trains,
              placeholder: this.place,
              percnt: this.skl,
              progress: this.pcnt,
              projectname: this.pname,
              projectsum: this.psum,
              pshorttitle: this.shorttitle1,
              projectdate: this.pdate,
              workexpr: this.rol,
              workexpr1: this.cname,
              workexpr2: this.clocation,
              workexpr4: this.workdate,
              workexpr5: this.esum,
              education: this.edu1,
              education1: this.edu2,
              education2: this.edudate,
              education4: this.eduloc,
              education5: this.educgpa,
              summary: this.sum,
              name: this.name,
              socialmedia: this.socialmedia,
              title: this.title1,
              langname: this.lang_name,
              rating: this.rating,
              to: this.level,
              color: this.color,
              img: "",
              myTimeChartInformation: JSON.parse(data[0].myTimeChartInformation)
            }, function () {
              this.dataLoadingComplete = true
              if (this.requestedAction === "generatePDF") {
                this.generatePDF();
              }
            });
          } else {
            this.trains = [""];
            this.place = ["Certifications"];
            this.title1 = ["SUMMARY", "EDUCATION", "CERTIFICATION", "PROFESSIONAL  SKILLS", "work experience", "PROJECTS", "LANGUAGE"];
            if (this.lk) {
              document.getElementsByClassName('lik1')[0].removeAttribute("data-html2canvas-ignore");
            }
            if (this.trains[0]) {
              document.getElementsByClassName('tc')[0].removeAttribute("data-html2canvas-ignore");
            }
            // if (this.educgpa) {
            //   document.getElementsByClassName('pg')[0].removeAttribute("data-html2canvas-ignore");
            // }
            this.setState({
              trainings: this.trains,
              placeholder: this.place,
              title: this.title1,
            }, function () {
              this.dataLoadingComplete = true
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  addMarker = (e) => {
    const { markers } = this.state;
    markers.push(e.latlng);
    this.setState({ markers });
  };

  onMyTimeInformationChange = (data) => {
    this.setState({
      myTimeChartInformation: data,
    });
    if (this.dataLoadingComplete) {
      this.insertdb(this.state);
    }
  };

  insertdb(state, forceSave = false) {
    if (localStorage.getItem("email") === null) {
      localStorage.setItem("trainings", state.trainings);
      localStorage.setItem("placeholder", state.placeholder);
      localStorage.setItem("percnt", state.percnt);
      localStorage.setItem("progress", state.progress);
      localStorage.setItem("projectname", state.projectname);
      localStorage.setItem("projectsum", state.projectsum);
      localStorage.setItem("pshorttitle", state.pshorttitle);
      localStorage.setItem("projectdate", state.projectdate);
      localStorage.setItem("workexpr", state.workexpr);
      localStorage.setItem("workexpr1", state.workexpr1);
      localStorage.setItem("workexpr2", state.workexpr2);
      localStorage.setItem("workexpr4", state.workexpr4);
      localStorage.setItem("workexpr5", state.workexpr5);
      localStorage.setItem("education", state.education);
      localStorage.setItem("education1", state.education1);
      localStorage.setItem("education2", state.education2);
      localStorage.setItem("education4", state.education4);
      localStorage.setItem("education5", state.education5);
      localStorage.setItem("summary", state.summary);
      localStorage.setItem("name", state.name);
      localStorage.setItem("socialmedia", state.socialmedia);
      localStorage.setItem("title", state.title);
      localStorage.setItem("langname", state.langname);
      localStorage.setItem("rating", state.rating);
      localStorage.setItem("to", state.to);
      localStorage.setItem("color", state.color);
      localStorage.setItem("img", this.fnm);
      localStorage.setItem("myTimeChartInformation", JSON.stringify(state.myTimeChartInformation));
      localStorage.setItem("store", "store");
    } else {
      if (this.dataLoadingComplete && forceSave) {
        this.setState({ loading: [true, "block"] });
        axios({
          method: "post",
          url: API_URL + "/resume1.php",
          data: {
            auth: state.auth,
            name: state.name,
            socialmedia: state.socialmedia,
            summary: state.summary,
            trainings: state.trainings,
            placeholder: state.placeholder,
            projectname: state.projectname,
            projectdate: state.projectdate,
            projectsum: state.projectsum,
            shorttitle: state.pshorttitle,
            skill: state.percnt,
            percnt: state.progress,
            role: state.workexpr,
            companyname: state.workexpr1,
            companylocation: state.workexpr2,
            workdate: state.workexpr4,
            worksum: state.workexpr5,
            educationname1: state.education,
            educationname2: state.education1,
            educationdate: state.education2,
            educationloc: state.education4,
            educationcgpa: state.education5,
            title: state.title,
            langname: state.langname,
            rating: state.rating,
            level: state.to,
            color: state.color,
            myTimeChartInformation: state.myTimeChartInformation
          },
        })
          .then((response) => {
            setTimeout(() => {
              this.setState({ loading: [false, "none"] });
            }, 3000);
          })
          .catch(function (response) {
            //    console.log(response)
          });

        localStorage.setItem("trainings", state.trainings);
        localStorage.setItem("placeholder", state.placeholder);
        localStorage.setItem("percnt", state.percnt);
        localStorage.setItem("progress", state.progress);
        localStorage.setItem("projectname", state.projectname);
        localStorage.setItem("projectsum", state.projectsum);
        localStorage.setItem("pshorttitle", state.pshorttitle);
        localStorage.setItem("projectdate", state.projectdate);
        localStorage.setItem("workexpr", state.workexpr);
        localStorage.setItem("workexpr1", state.workexpr1);
        localStorage.setItem("workexpr2", state.workexpr2);
        localStorage.setItem("workexpr4", state.workexpr4);
        localStorage.setItem("workexpr5", state.workexpr5);
        localStorage.setItem("education", state.education);
        localStorage.setItem("education1", state.education1);
        localStorage.setItem("education2", state.education2);
        localStorage.setItem("education4", state.education4);
        localStorage.setItem("education5", state.education5);
        localStorage.setItem("summary", state.summary);
        localStorage.setItem("name", state.name);
        localStorage.setItem("socialmedia", state.socialmedia);
        localStorage.setItem("title", state.title);
        localStorage.setItem("langname", state.langname);
        localStorage.setItem("rating", state.rating);
        localStorage.setItem("to", state.to);
        localStorage.setItem("color", state.color);
        localStorage.setItem("store", "store");
      }
    }
  }

  fname(e) {
    const state = this.state
    state.name[0] = e.target.innerHTML;
    this.setState({ name: state.name });
    this.insertdb(state);
  }
  name1(e) {
    const state = this.state
    state.name[1] = e.target.innerHTML;

    this.setState({
      name: state.name,
    });
    this.insertdb(state);
  }
  name2(e) {
    const state = this.state
    state.name[2] = e.target.innerHTML;

    this.setState({
      name: state.name,
    });
    this.insertdb(state);
  }
  email(e) {
    const state = this.state
    state.socialmedia[0] = e.target.innerHTML;
    this.setState({
      socialmedia: state.socialmedia,
    });
    this.insertdb(state);
  }
  phno(e) {
    const state = this.state
    state.socialmedia[1] = e.target.innerHTML;

    this.setState({
      socialmedia: state.socialmedia,
    });
    this.insertdb(state);
  }
  link(e) {
    const state = this.state
    state.socialmedia[2] = e.target.innerHTML;

    this.setState({
      socialmedia: state.socialmedia,
    });
    this.insertdb(state);
    state.socialmedia[2] ? document.getElementsByClassName('lik1')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('lik1')[0].setAttribute('data-html2canvas-ignore', 'true');
  }
  add(e) {
    const state = this.state
    state.socialmedia[3] = e.target.innerHTML;

    this.setState({
      socialmedia: state.socialmedia,
    });
    this.insertdb(state, true);
  }

  summary(e) {
    this.setState(
      {
        summary: e.target.innerHTML,
      },
      this.insertdb(this.state)
    );
  }

  langone(key, value) {
    const state = this.state
    state.workexpr[key] = value.target.innerHTML;
    this.setState({
      workexpr: state.workexpr,
    });
    this.insertdb(state);
  }
  langone1(key, value) {
    const state = this.state
    state.workexpr1[key] = value.target.innerHTML;
    this.setState({
      workexpr1: state.workexpr1,
    });
    this.insertdb(state);
  }
  langone2(key, value) {
    const state = this.state
    state.workexpr2[key] = value.target.innerHTML;
    this.setState({
      workexpr2: state.workexpr2,
    });
    this.insertdb(state);
  }
  langname(key, value) {
    const state = this.state
    state.langname[key] = value.target.innerHTML;
    this.setState({
      langname: state.langname,
    });
    this.insertdb(state);
    this.state.langname[key] ? document.getElementsByClassName('lg')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('lg')[0].setAttribute('data-html2canvas-ignore', 'true');
  }

  langone4(key, value) {
    const monname = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dt3 = monname[value.target.value[0].getMonth()] + " " + value.target.value[0].getFullYear();
    var dt4 = monname[value.target.value[1].getMonth()] + " " + value.target.value[1].getFullYear();
    const state = this.state
    state.workexpr4[key] = dt3 + " - " + dt4;
    this.setState({
      workexpr4: state.workexpr4,
    });
    this.insertdb(state);
  }
  langone5(key, value) {
    const state = this.state
    state.workexpr5[key] = value.target.innerHTML;
    this.setState({
      workexpr5: state.workexpr5,
    });
    this.insertdb(state);
  }
  education(value, key) {
    const state = this.state
    state.education[value] = key.target.innerHTML;
    this.setState({
      education: state.education,
    });
    this.insertdb(state);
    this.state.education[key] ? document.getElementsByClassName('eg')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('eg')[0].setAttribute('data-html2canvas-ignore', 'true');
  }
  education1(key, value) {
    const state = this.state
    state.education1[key] = value.target.innerHTML;
    this.setState({
      education1: state.education1,
    });
    this.insertdb(state);
    this.state.education1[key] ? document.getElementsByClassName('eg')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('eg')[0].setAttribute('data-html2canvas-ignore', 'true');
  }
  education2(key, value) {
    const state = this.state
    const monname = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    state.education2[key] = monname[value.target.value.getMonth()] + "-" + value.target.value.getFullYear();
    this.setState({
      education2: state.education2,
    });
    state.education2[key] ? document.getElementsByClassName('eg')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('eg')[0].setAttribute('data-html2canvas-ignore', 'true');
    this.insertdb(state);
  }
  education3(key, value) {
    const state = this.state
    state.education3[key] = value.target.innerHTML;
    this.setState({
      education3: state.education3,
    });
    this.insertdb(state);
  }
  education4(key, value) {
    const state = this.state
    state.education4[key] = value.target.innerHTML;
    this.setState({
      education4: state.education4,
    });
    this.insertdb(state);
    state.education4[key] ? document.getElementsByClassName('eg')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('eg')[0].setAttribute('data-html2canvas-ignore', 'true');
  }
  education5(key, value) {
    const state = this.state
    state.education5[key] = value.target.innerHTML;
    this.setState({
      education5: state.education5,
    });
    this.insertdb(state);
    state.education5[key] ? document.getElementsByClassName('pg')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('pg')[0].setAttribute('data-html2canvas-ignore', 'true');
  }

  addwork() {
    this.setState({
      workexpr: [...this.state.workexpr, ""],
    });
    this.insertdb(this.state, true);
  }
  addlang() {
    this.setState({
      langname: [...this.state.langname, ""],
      rating: [...this.state.rating, "1"],
      to: [...this.state.to, "Beginner"],
    });
    this.insertdb(this.state, true);
  }
  remlang(e) {
    this.state.langname.splice(-1);
    this.state.rating.splice(-1);
    this.state.to.splice(-1);
    this.setState({
      langname: this.state.langname,
      rating: this.state.rating,
      to: this.state.to,
    }, function () {
      this.insertdb(this.state, true);
    });
  }

  addToMyTime() {
    this.setState({
      myTimeChartInformation: [
        ...this.state.myTimeChartInformation,
        {
          proportion: 1,
          skill: "",
        },
      ],
    });
    this.insertdb(this.state, true);
  }
  removeFromMyTime(e) {
    let myTimeChartInformation = [...this.state.myTimeChartInformation];
    myTimeChartInformation.splice(-1);
    this.setState({
      myTimeChartInformation: myTimeChartInformation,
    }, function () {
      this.insertdb(this.state, true);
    });
  }

  remwork(e) {
    this.state.workexpr.splice(e, 1);
    this.setState({
      workexpr: this.state.workexpr,
    }, function () {
      this.insertdb(this.state, true);
    });
  }

  addedu() {
    this.setState({
      education: [...this.state.education, ""],
    });
    this.insertdb(this.state, true);
  }
  remedu(e) {
    this.state.education.splice(-1);
    this.setState({
      education: this.state.education,
    }, function () {
      this.insertdb(this.state, true);
    });
  }
  addpro() {
    this.setState({
      progress: [...this.state.progress, "0"],
      percnt: [...this.state.percnt, ""],
    });
    this.insertdb(this.state, true);
  }
  rempro(e) {
    console.log(e);

    this.state.progress.splice(-1);
    this.setState({
      progress: this.state.progress,
    }, function () {
      this.insertdb(this.state, true);
    });
  }
  addproject(e) {
    this.setState({
      projectname: [...this.state.projectname, ""],
    });
    this.insertdb(this.state, true);
  }
  remproject(e) {
    this.state.projectname.splice(-1);
    this.setState({
      projectname: this.state.projectname,
    }, function () {
      this.insertdb(this.state, true);
    });
  }
  addtracer(e) {
    this.setState({
      trainings: [...this.state.trainings, ""],
      placeholder: [...this.state.placeholder, "Certifications"],
    });
    this.insertdb(this.state, true);
  }

  remtracer(e) {
    this.state.trainings.splice(-1);
    this.state.placeholder.splice(-1);

    this.setState({
      trainings: this.state.trainings,
      placeholder: this.state.placeholder,
    }, function () {
      this.insertdb(this.state, true);
    });
  }

  delete(e, k, n) {
    this.state[k].splice(-1);
    this.state.placeholder.splice(-1);

    this.setState({
      [k]: this.state[k],
    });
    this.insertdb(this.state, true);
  }
  toggle(e, k, n) {
    $("#" + k + e).toggle(1000, () => {
      document.getElementById(k + e).style.display === "none" ? $("#add" + e).removeClass("fa fa-minus-circle") : $("#add" + e).removeClass("fa fa-plus-circle");

      document.getElementById(k + e).style.display === "none" ? $("#add" + e).addClass("fa fa-plus-circle") : $("#add" + e).addClass("fa fa-minus-circle");
    });
    $("#label" + e).toggle(1000, () => {
      document.getElementById(k + e).style.display === "none" ? $("#tridiv" + e).css("border", "none") : $("#tridiv" + e).css("border", "");
      document.getElementById(k + e).style.display === "none" ? $("#opt" + e).css("display", "none") : $("#opt" + e).css("displat", "block");
    });
  }

  skill(value, e) {
    const state = this.state
    state.percnt[value] = e.target.innerHTML;
    this.setState({
      percnt: state.percnt,
    });
    this.insertdb(state);
    state.percnt[value] ? document.getElementsByClassName('sk')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('sk')[0].setAttribute('data-html2canvas-ignore', 'true');
  }

  trainings(value, key) {
    const state = this.state
    state.trainings[value] = key.target.innerHTML;
    this.setState({
      trainings: state.trainings,
    });
    this.insertdb(state);
    state.trainings[value] ? document.getElementsByClassName('tc')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('tc')[0].setAttribute('data-html2canvas-ignore', 'true');
  }

  projectname(value, key) {
    const state = this.state
    state.projectname[value] = key.target.innerHTML;
    this.setState({
      projectname: state.projectname,
    });
    this.insertdb(state);
  }
  projectdate(value, key) {
    const monname = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dt3 = monname[key.target.value[0].getMonth()] + " - " + key.target.value[0].getFullYear();
    var dt4 = monname[key.target.value[1].getMonth()] + " " + key.target.value[1].getFullYear();
    const state = this.state
    state.projectdate[value] = dt3 + " - " + dt4;
    this.setState({
      projectdate: state.projectdate,
    });
    this.insertdb(state);
  }

  projectsum(value, key) {
    const state = this.state
    state.projectsum[value] = key.target.innerHTML;
    this.setState({
      projectsum: state.projectsum,
    });
    this.insertdb(state);
  }

  shorttitle(value, key) {
    const state = this.state
    state.pshorttitle[value] = key.target.innerHTML;
    this.setState({
      pshorttitle: state.pshorttitle,
    });
    this.insertdb(state);
  }

  prog(value, e) {
    var prog = e.text;
    const state = this.state
    state.progress[value] = prog;
    this.setState({
      progress: state.progress,
    });
    this.insertdb(state);
    state.progress[value] ? document.getElementsByClassName('sk')[0].removeAttribute('data-html2canvas-ignore') : document.getElementsByClassName('sk')[0].setAttribute('data-html2canvas-ignore', 'true');
  }

  getOffSetTopFromRoot(child) {
    var offset = $(child).offset().top
    return offset - $("#container1").offset().top
  }

  generatePDF() {
    if (localStorage.getItem("email") === null) {
      this.props.history.push('/login');
      return
    }
    this.insertdb(this.state, true);
    var adjustedElements = [];
    var pageHeight = 1120
    $(".cl, .mytime-module").each(function (index, element) {
      if (!$(element).hasClass("row")) {
        var elementStartPosition = this.getOffSetTopFromRoot(element)
        let currentPage = Math.floor(elementStartPosition / pageHeight)
        let borderOffset = pageHeight + (currentPage * pageHeight)
        let elementEndPosition = elementStartPosition + $(element).innerHeight()
        if ((elementStartPosition < borderOffset && elementEndPosition > borderOffset) || (elementStartPosition < borderOffset && (borderOffset - elementStartPosition) < 20)) {
          $(element).css('margin-top', ((borderOffset - elementStartPosition) + 50) + 'px');
          adjustedElements.push(element)
        }
      }
    }.bind(this));
    var element = document.getElementById('container1');
    var opt = {
      margin: 0,
      filename: 'cv.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: { unit: 'in' },
      html2canvas: { scale: 2, scrollY: 0 },
      pagebreak: { mode: 'css' }
    };
    $(".e-icons, .fa-map-marker").each(function () {
      $(this).hide()
    });
    $(".location-name").each(function () {
      $(this).css({ 'margin-left': '-15px' })
    });

    $(".edu-dates").each(function () {
      if (!$(this).val().length) {
        $(this).hide();
      }
    });

    // New Promise-based usage:
    html2pdf(element, opt).then(function (response) {
      document.getElementById("generatePDFButton").scrollIntoView();
      $(".e-icons, .fa-map-marker, .edu-dates").each(function () {
        $(this).show()
      });
      $(".location-name").each(function () {
        $(this).css({ 'margin-left': '0px' })
      });

      adjustedElements.forEach(function (element) {
        $(element).css('margin-top', '0px');
      });
    });
  }

  linked1(e) {
    var link = document.getElementById(e.target.id).getElementsByTagName("a")[0] ? document.getElementById(e.target.id).getElementsByTagName("a")[0].href : e.target.innerHTML;
    const state = this.state
    state.socialmedia[4] = link;
    this.setState({
      socialmedia: state.socialmedia,
    });
    this.insertdb(state);
  }

  onclose(e) {
    if (e.toastContainer.childElementCount === 0) {
      this.hideTosat.element.style.display = "none";
    }
  }
  check_charcount(e) {
    var max = 700;
    if (e.which !== 8 && $("#" + e.target.id).text().length > max) {
      $("#" + e.target.id).text(
        $("#" + e.target.id)
          .text()
          .substring(0, max)
      );
      var range = document.createRange();
      var sel = window.getSelection();
      if (e.target.innerHTML.length > -1) {
        // if text contains Mari
        range.setStart(e.target.childNodes[0], e.target.innerText.length);
      }
      e.preventDefault();
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      this.toastObj.show(this.toasts[0]);
    }
    this.summary(e);
  }

  title = (e, n) => {
    const state = this.state
    state.title[n] = e.target.innerHTML;
    this.setState({
      title: state.title,
    });
    this.insertdb(state);
  };
  focs(e) {
    var el = $("#" + e.target.id);
    if (typeof el.selectionStart === "number") {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange !== "undefined") {
      el.focus();
      var range = el.createTextRange();
      range.collapse(false);
      range.select();
    }
  }
  placeCaretAtEnd(el) {
    if (typeof window.getSelection !== "undefined" && typeof document.createRange !== "undefined") {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== "undefined") {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  handleRate(key, { rating }) {
    const { rating: lastRating } = this.state;
    if (rating === lastRating) {
      return;
    }
    const rate = ["", "Beginner", "Intermediate", "Advanced", "Proficient", "Native"];
    const to = rate[rating];
    const state = this.state
    state.to[key] = to;
    state.rating[key] = rating;
    this.setState({ rating: state.rating, to: state.to }, this.insertdb(state));
  }
  onCrop = () => {
    const { editor } = this.state;
    if (editor !== null) {
      const img = editor.getImageScaledToCanvas().toDataURL();
      const rect = editor.getCroppingRect();
      this.setState({
        img: {
          img,
          rect,
          scale: this.state.zoom,
          width: "150",
          height: "150",
          borderRadius: "100",
        },
        file: img,
      });

      document.getElementById("bgimagecrop").style.display = "none";
      document.getElementById("imagecropsave").style.display = "none";
      document.getElementById("cancel").style.display = "none";

      document.getElementById("bgimageview").style.visibility = "hidden";
      document.getElementById("bgimageview").style.display = "block";
      document.getElementById("ok").style.display = "block";
      document.getElementById("imgload").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("imgload").style.visibility = "hidden";
        document.getElementById("bgimageview").style.visibility = "visible";

        document.getElementById("bgimageview").style.backgroundImage = "url('" + img + "')";
        document.getElementById("profile").src = img;

        if (localStorage.getItem("email") === null) {
          this.fnm = img;
          localStorage.setItem("img", this.fnm);
          localStorage.setItem("store", "store");
        } else {
          localStorage.setItem("img", img);
          localStorage.setItem("store", "store");

          axios({
            method: "post",
            url: "./image.php",
            data: { image: img, auth: this.state.auth, oldimg: this.fnm },
            config: { headers: { "content-type": "multipart/form-data" } },
          })
            .then(function (res) {
              // console.log(res)
              this.fnm = "./images/" + res.data;
            })
            .catch(function (err) {
              //  console.log(err)
            });
        }
      }, 3000);
    }
  };
  setEditorRef = (editor) => this.setState({ editor });
  zoom = (e) => {
    this.setState({ zoom: e.text.replace("%", "") });
  };
  imgupload(e) {
    var file = e.target.files[0];
    document.getElementById("bgimageview").style.display = "none";
    document.getElementById("ok").style.display = "none";
    document.getElementById("bgimagecrop").style.display = "block";
    document.getElementById("imagecropsave").style.display = "block";
    document.getElementById("cancel").style.display = "block";
    this.setState({ file: file });
  }
  change(args) {
    this.setState(
      {
        color: args.currentValue.hex,
      },
      this.insertdb(this.state, true)
    );
  }

  beforeCircleTileRender(args) {
    args.element.classList.add("e-circle-palette");
    args.element.appendChild(createElement("span", { className: "e-circle-selection" }));
  }

  updateResumeFont(event) {
    const font = event.target.id
    this.setState({
      activeFont: font
    });
    if (font === "arial") {
      $(".mt *").not("i").css("font-family", "Arial, Helvetica, sans-serif");
      $(".font-type").css("font-family", '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif');
    } else if (font === "timesnewroman") {
      $(".mt *").not("i").css("font-family", "Times New Roman, Times, serif");
      $(".font-type").css("font-family", '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif');
    } else {
      $(".mt *").not("i").css("font-family", '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif');
    }
  }

  render() {
    return (
      <div className="mt">
        <div className='e-circle-wrap' id="c1">
          <ColorPickerComponent id='circle-palette' mode='Palette' modeSwitcher={false} inline={true} showButtons={false} columns={4} presetColors={this.circlePaletteColors} beforeTileRender={this.beforeCircleTileRender.bind(this)} change={this.change.bind(this)}></ColorPickerComponent>
        </div>
        <div className="e-circle-wrap e-custom-palette" id="f1">
          <h6 id="applesystem" onClick={this.updateResumeFont.bind(this)} className={`font-type ${this.state.activeFont === "applesystem" ? "activeFont" : ""}`}>
            Apple System
          </h6>
          <h6 id="arial" onClick={this.updateResumeFont.bind(this)} className={`font-type ${this.state.activeFont === "arial" ? "activeFont" : ""}`}>
            Arial
          </h6>
          <h6 id="timesnewroman" onClick={this.updateResumeFont.bind(this)} className={`font-type ${this.state.activeFont === "timesnewroman" ? "activeFont" : ""}`}>
            Times New Roman
          </h6>
        </div>
        <div id="my-modal" class="qm-modal">
          <div class="qm-close-modal" onClick={() => { document.getElementById('bgimagecrop').style.display = "none"; document.getElementById('bgimageview').style.display = "block" }}></div>
          <h3>Photo</h3>
          <hr></hr>
          <div style={{ height: '330px', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="bg-image" id="bgimageview" style={{ backgroundImage: `${this.fnm ? "url(" + this.fnm : "url(./user.jpeg)"}`, display: "block" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", visibility: "hidden" }} id="imgload">
                <ClipLoader
                  size={25}
                  color={"#123abc"}
                  loading={true}
                />
                <h5>&nbsp; Uploading...</h5>
              </div>
            </div>
            <div id="bgimagecrop" style={{ display: "none" }}>
              <AvatarEditor

                ref={this.setEditorRef}
                image={this.state.file}
                width={150}
                height={150}
                border={50}
                scale={this.state.zoom}
                borderRadius={100}
              />
              <SliderComponent id={'slidercrop'} step="0.01" change={(e) => this.zoom(e)} tooltipChange={this.tooltipChangeHandler.bind(this)} min={1} max={10} ticks={this.ticks} tooltip={this.tooltip} type='MinRange' />
            </div>
          </div>
          <hr></hr>
          <div >
            <span style={{ display: "flex", filter: "opacity(0.5)" }}>Import Photo</span>
            <button className="btn btn-primary  " onClick={() => this.imges.click()}>From Computer</button>
            <input name="images" onChange={(e) => this.imgupload(e)} accept="image/*" ref={(ref) => this.imges = ref} type="file" style={{ display: "none" }} />
            <button className="btn btn-danger float-right cancel" id="cancel" onClick={() => { document.getElementById('ok').style.display = "block"; document.getElementById('bgimagecrop').style.display = "none"; document.getElementById('cancel').style.display = "none"; document.getElementById('imagecropsave').style.display = "none"; document.getElementById('bgimageview').style.display = "block" }} style={{ display: "none", marginLeft: '2%' }} >CANCEL</button>
            <button className="btn btn-success float-right" id="imagecropsave" style={{ display: "none" }} onClick={this.onCrop}>SAVE</button>
            <button className="btn btn-success float-right save" id="ok" onClick={() => { document.getElementById('ok').style.display = "block"; document.getElementById('bgimagecrop').style.display = "none"; document.getElementById('cancel').style.display = "none"; document.getElementById('imagecropsave').style.display = "none"; document.getElementById('bgimageview').style.display = "block" }}>OK</button>
          </div>
        </div>
        <div className="loading" id="loading" style={{ display: this.state.loading[1] }}>
          <ClipLoader
            size={20}
            color={"#123abc"}
            loading={this.state.loading[0]}
          />
          <label>Saving...</label>
        </div>
        <ToastComponent ref={(toast) => { this.toastObj = toast; }} id='toast_type' position={this.position} close={this.onclose.bind(this)} ></ToastComponent>
        <div className="gd" id="grid2">
          <div className="container container1" id="1">

            <div className="row" id="container1" style={{ backgroundColor: "white" }}>
              <div className="col-md-12" style={{ padding: "2% 6%" }} /*style={{backgroundColor: "#dddddd85"}}*/ >
                <div className="row">
                  <div className="col-md-5">
                    <ul type="none" style={{ marginTop: "1rem", padding: '2% 0%' }}>
                      <div className="fname" style={{ fontWeight: 'bold' }} placeholder="Your" suppressContentEditableWarning={true} contentEditable="true" onKeyUp={this.fname.bind(this)} id="fname">
                        {this.fn}
                      </div>
                      <div className="lname" style={{ fontWeight: "bold" }} placeholder="Name" suppressContentEditableWarning={true} contentEditable="true" onKeyUp={this.name1.bind(this)} id="lname">
                        {this.ln}
                      </div>
                      <div className="role" suppressContentEditableWarning={true} contentEditable="true" placeholder="SOCIAL MEDIA SPECIALIST" onKeyUp={this.name2.bind(this)} id="spl">
                        {this.rl}
                      </div>
                    </ul>
                  </div>
                  <div className="col-md-2" style={{ cursor: "pointer" }}>
                    <div style={{ marginTop: "1rem" }}>
                      <div class="cv-class text-center">
                        <img style={{ cursor: "pointer", borderRadius: "80px", width: "10rem", height: "10rem" }} id="profile" className="img-responsive" data-src="holder.js/100x100" alt="CV" src={this.fnm && this.fnm !== "undefined" ? this.fnm : "./user.jpeg"} data-itemprop="image" />
                        <button data-html2canvas-ignore="true" type="button" data-modal-id="my-modal" class="btn btn-primary btn-icon open-modal"><i class="fa fa-cloud-upload btn-icon-single"></i></button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <ul type="none" style={{ width: "100%" }}>
                      <li id='bar'><i className="fa fa-envelope i"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span suppressContentEditableWarning={true} contentEditable="true" style={{ fontSize: "12px" }} className="dflt" id="email" onInput={this.email.bind(this)} placeholder="p.smith@gmail.com">{this.em}</span></li>
                      <li id='bar'><i className="fa fa-phone i"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span suppressContentEditableWarning={true} contentEditable="true" style={{ fontSize: "12px" }} id="phno" onInput={this.phno.bind(this)} className="dflt" placeholder="+1 367 6789 897">{this.pn}</span></li>
                      <li title="Optional" id='bar' class="lik1" data-ignore-case="true"><i className="	fa fa-globe i"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span suppressContentEditableWarning={true} contentEditable="true" style={{ fontSize: "12px" }} id="link" onInput={this.link.bind(this)} className="dflt" placeholder="example https://github.com">{this.lk}</span></li>
                      <li id='bar'><i className="fa fa-home i"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span suppressContentEditableWarning={true} contentEditable="true" style={{ fontSize: "12px" }} onInput={this.add.bind(this)} id="tes" className="dflt pre-line" placeholder="21 E 27th st,New York NY 1245,USA">{this.address}</span></li>
                      <li id='bar'><a rel="noopener noreferrer" href={"https://www.linkedin.com/in/" + this.state.socialmedia[4]} target="_blank"><i className="fa fa-linkedin i"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span contentEditable="true" suppressContentEditableWarning={true} onInput={this.linked1.bind(this)} id='lnk' style={{ fontSize: "12px" }} className="dflt" placeholder="Enter the linkedin Profile Name">{this.lked}</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12" style={{ padding: "2% 6%" }}>
                <div className="row">
                  <div className="col-md-12">
                    <b><span className="dflt dflt1 header t pre-line" suppressContentEditableWarning={true} style={{ color: `${this.state.color}` }} contentEditable="true" onBlur={(e) => this.title(e, 0)} id={"sumt"} placeholder="SUMMARY" >{this.title1 ? this.title1[0] : 'SUMMERY'}</span>  </b>
                    <label className='float-right' data-html2canvas-ignore="true"
                    ></label>
                    <hr />
                    <div suppressContentEditableWarning={true}  style={{ fontSize: "14px" }} id="summary" className="dflt pre-line" contentEditable="true" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ">{this.sum}</div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <div className="col-md-12 left" id="mainleft1" style={{ padding: "2% 6%" }}>
                <div className="row sortable-list  droppable-area1 connected-sortable">
                  <div className="col-md-12 eg " >
                    <div className="row rowt">
                      <div className="col-md-12 cl handle"><i style={{ fontSize: "25px", position: "absolute", right: "94%" }} aria-hidden="true" className="fa fa-graduation-cap i handle"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b style={{ marginLeft: '1em' }}><span className="dflt dflt1 header t pre-line" onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" style={{ color: `${this.state.color}` }} onBlur={(e) => this.title(e, 1)} id={"edut"} placeholder="EDUCATION" >{this.title1 ? this.title1[1] : 'EDUCATION'}</span></b> &nbsp;&nbsp;<i onClick={this.addedu.bind(this)} style={{ color: "green", fontSize: "20px", cursor: "pointer" }} className="fa fa-plus-circle i" id='ico1' data-html2canvas-ignore="true"
                      ></i>&nbsp;&nbsp;<i data-html2canvas-ignore="true"
                        onClick={this.remedu.bind(this)} style={{ color: "red", fontSize: "20px", cursor: "pointer" }} className="fa fa-minus-circle i" id='ico2'  ></i><hr /></div>
                    </div>
                    <div className="row">
                      <edit id="sortable" className=" col-md-12 left1">
                        {
                          this.state.education.map((key, value) => {
                            return (
                              <div className="col-md-12 cl " style={{ padding: "0% 0%" }}>
                                <div className="row">
                                  <div className="col-md-8">
                                    <span className="dflt  roles pre-line" style={{ textTransform: "capitalize", fontSize: '14px' }} suppressContentEditableWarning={true} contentEditable="true" onClick={(e) => this.focs(e)} onBlur={this.education.bind(this, value)} id={"edu" + value} placeholder="Degree and Field of Study" >{this.state.education[value]}</span><br />
                                    <b><span className="dflt dflt1 pre-line" style={{ fontSize: "14px" }} onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" onBlur={this.education1.bind(this, value)} id={"educ" + value} placeholder="School or University" >{this.state.education1[value]}</span></b>

                                    <div className="row">
                                      <div className="col-md-12">
                                        <i className="fa fa-map-marker i f14 map-mark pre-line" ></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ fontSize: "12px" }} onClick={(e) => this.focs(e)} tabIndex="0" onBlur={this.education4.bind(this, value)} id={"loc" + value} suppressContentEditableWarning={true} contentEditable="true" className="dflt roles pre-line location-name" placeholder="Location" >{this.state.education4[value]}</span>

                                      </div>
                                      <div className="col-md-3">
                                        <DatePickerComponent id={"efd" + value} value={this.state.education2[value]} start={this.start} depth={this.depth} onChange={this.education2.bind(this, value)} format='MMM y' placeholder='MM-YYYY'></DatePickerComponent><br />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </edit>
                    </div>


                  </div>
                  <div className="col-md-12 tc" style={{ paddingTop: '1em' }} >
                    <div className="row rowt">

                      <div className="col-md-12 cl handle"> <i style={{ fontSize: "25px", position: "absolute", right: "95%" }} className="fa fa-certificate i handle pre-line" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b ><span className="dflt dflt1 header t" style={{ color: `${this.state.color}` }} onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" onBlur={(e) => this.title(e, 2)} id={"trit"} placeholder="CERTIFICATION" >{(this.state.title ? this.state.title[2] : "TRAINING / CERTIFICATION")}</span></b>&nbsp;&nbsp;<i style={{ color: "green", fontSize: "20px", cursor: "pointer" }} onClick={this.addtracer.bind(this)} className="fa fa-plus-circle i" id="ico3" data-html2canvas-ignore="true"
                      ></i>&nbsp;&nbsp;<i onClick={this.remtracer.bind(this)} style={{ color: "red", fontSize: "20px", cursor: "pointer" }} className="fa fa-minus-circle i" id="ico4" data-html2canvas-ignore="true"
                      ></i><hr /></div>
                    </div>
                    <div className="row">

                      <edit id="sortable1" className="col-md-12 left2">
                        {
                          this.state.trainings.map((value, key5) => {
                            return (<div style={{ padding: "0% 0%" }} className="cl">

                              <span onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} style={{ fontSize: "14px" }} tabIndex="1" id={"training" + key5} onBlur={this.trainings.bind(this, key5)} contentEditable="true" className="dflt roles pre-line" placeholder={this.state.placeholder[key5]}>{this.state.trainings[key5]}</span><br />

                            </div>)
                          })
                        }
                      </edit>
                    </div>
                  </div>
                  <div className="col-md-12 sk" style={{ paddingTop: '1em' }} >
                    <div className="row cl rowt">
                      <div className="col-md-12 cl handle"><i style={{ fontSize: "25px", position: 'absolute', right: "94%" }} className="fa fa-line-chart i handle" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><span className="dflt dflt1 header t pre-line" style={{ color: `${this.state.color}` }} onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" onBlur={(e) => this.title(e, 3)} id={"proft"} placeholder="PROFESSIONAL  SKILLS" >{(this.state.title ? this.state.title[3] : "PROFESSIONAL SKILLS")}</span></b>&nbsp;&nbsp;<i style={{ color: "green", fontSize: "20px", cursor: "pointer" }} onClick={this.addpro.bind(this)} className="fa fa-plus-circle i" id="ico5" data-html2canvas-ignore="true"
                      ></i>&nbsp;&nbsp;<i onClick={this.rempro.bind(this)} style={{ color: "red", fontSize: "20px", cursor: "pointer" }} id="ico6" className="fa fa-minus-circle i" data-html2canvas-ignore="true"
                      ></i><hr /></div>
                    </div>
                    <div className="row cl">

                      <edit className="col-md-12 left3">
                        {
                          this.state.progress.map((key, value) => {
                            return (

                              <div className='cl' style={{ display: "flex", marginBottom: '2vh', padding: "0% 0%" }} >
                                <div style={{ width: '40%' }}>
                                  <span onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} id={"profskill" + value} contentEditable="true" className="dflt roles pre-line" style={{ textTransform: "capitalize" }} placeholder="Photoshop" onBlur={this.skill.bind(this, value)} >{this.state.percnt[value]}</span>
                                </div>
                                <div className="col-md-2">
                                  <span class="badge badge-dark" id={"badge" + value}>{key}</span>
                                </div>
                                <div className="col-md-6">

                                  <SliderComponent id={'slider' + value} change={this.prog.bind(this, value)} tooltipChange={this.tooltipChangeHandler.bind(this)} value={key} min={0} max={100} ticks={this.ticks} tooltip={this.tooltip} type='MinRange' />
                                </div>
                              </div>

                            )
                          })
                        }
                      </edit>
                    </div>
                  </div>

                  <div className="col-md-12 lg" style={{ paddingTop: '1em' }} >
                    <div className="row rowt cl">
                      <div className="col-md-12 cl handle"> <i style={{ fontSize: "25px", position: 'absolute', right: "95%" }} className="fa fa-language i" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <b><span className="dflt dflt1 header t pre-line" style={{ color: `${this.state.color}` }} onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" onBlur={(e) => this.title(e, 6)} id={"langt"} placeholder="LANGUAGE" >{(this.state.title ? this.state.title[6] : "LANGUAGE")}</span>  </b>&nbsp;&nbsp;<i style={{ color: "green", fontSize: "20px", cursor: "pointer" }} onClick={this.addlang.bind(this)} className="fa fa-plus-circle i" id="ico7" data-html2canvas-ignore="true"
                      ></i>&nbsp;&nbsp;<i onClick={this.remlang.bind(this)} style={{ fontSize: "20px", cursor: "pointer" }} id="ico8" className="fa fa-minus-circle i" data-html2canvas-ignore="true"
                      ></i>
                        <hr />  </div>
                    </div>
                    <div className="row cl">
                      <div id="sortable6" className="col-md-12">
                        <edit id="sortable6" className="row left4" style={{ padding: '0%', margin: '1px' }}>

                          {
                            this.state.langname.map((value, key4) => {
                              return (
                                <div className="col-md-6 cl" style={{ padding: "0% 0%" }}>
                                  <div className="row" >
                                    <div className="col-md-6">
                                      <b><span onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" id={"langn" + key4} onBlur={this.langname.bind(this, key4)} className="dflt roles pre-line" style={{ textTransform: "capitalize", fontSize: '14px' }} placeholder="Language" ></span></b><br />
                                      <span className="dflt roles" style={{ fontSize: "12px" }}>{this.state.to[key4]}</span>
                                    </div>
                                    <div className="col-md-6">
                                      <Rater rating={this.state.rating[key4]} total={5} id={"rate" + key4} onRate={this.handleRate.bind(this, key4)} >
                                        <NewStar />
                                      </Rater>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          }

                        </edit>
                      </div>
                    </div>

                  </div>

                  <div className="col-md-12 pt" style={{ paddingTop: '1em' }}>
                    <div className="row rowt">
                      <div className="col-md-12 cl handle"> <i style={{ fontSize: "25px", position: 'absolute', right: "95%" }} aria-hidden="true" className="fa fa-briefcase i handle"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b ><span className="dflt dflt1 header t pre-line" style={{ color: `${this.state.color}` }} onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" onBlur={(e) => this.title(e, 4)} id={"workt"} placeholder="WORK EXPERIENCE" >{(this.state.title ? this.state.title[4] : "WORK EXPERIENCE")}</span> </b> &nbsp;&nbsp; <i onClick={this.addwork.bind(this)} style={{ color: "green", fontSize: "20px", cursor: "pointer" }} className="fa fa-plus-circle i" id="ico9" data-html2canvas-ignore="true"
                      ></i>&nbsp;&nbsp;<i onClick={this.remwork.bind(this)} style={{ color: "red", fontSize: "20px", cursor: "pointer" }} className="fa fa-minus-circle i" id="ico10" data-html2canvas-ignore="true"
                      ></i>
                        <hr /></div>
                    </div>
                    <div className="row">
                      <edit className="col-md-12 left5" id="sortable4">
                        {
                          this.state.workexpr.map((key, value) => {
                            return (

                              <div className="cl" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div className="dn" style={{ padding: '0% 0%' }}>
                                  <span onClick={(e) => this.focs(e)} id={"spec" + value} onBlur={this.langone.bind(this, value)} suppressContentEditableWarning={true} contentEditable="true" placeholder="SOCIAL MEDIA SPECIALIST" className="dflt  roles pre-line" style={{ textTransform: "capitalize" }}>{this.state.workexpr[value]}</span> - <span id={"comp" + value} onClick={(e) => this.focs(e)} onBlur={this.langone1.bind(this, value)} style={{ textTransform: "capitalize" }} contentEditable="true" className="dflt roles pre-line" placeholder="Apple-Inc" >{this.state.workexpr1[value]}</span>
                                </div>


                                <div style={{ padding: '0%' }} >
                                  <div className="row">
                                    <div className="col-md-12 dn">
                                      <i className="fa fa-map-marker i f14 map-mark1" ></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ fontSize: "12px" }} onClick={(e) => this.focs(e)} tabIndex="0" onBlur={this.langone2.bind(this, value)} id={"wloc" + value} suppressContentEditableWarning={true} contentEditable="true" className="dflt roles location-name" placeholder="Location" >{this.state.workexpr2[value]}</span>
                                    </div>

                                  </div>
                                  <div style={{ width: '25%' }}>

                                    <DateRangePickerComponent start={this.start} depth={this.depth} onChange={this.langone4.bind(this, value)} id={"wfd" + value} value={this.state.workexpr4[value]} format='MMMM yyyy' placeholder='MM-YYYY  MM-YYYY'></DateRangePickerComponent>
                                  </div>
                                  <p onClick={(e) => this.focs(e)} id={"wpara" + value} style={{ marginTop: "7px", textAlign: "justify", fontSize: '14px' }} onBlur={this.langone5.bind(this, value)} suppressContentEditableWarning={true} contentEditable="true" className="dflt pre-line" placeholder="The number of lines in a paragraph depends on the size of the browser window. If you resize the browser window, the number of lines in this paragraph will change.
              This paragraph contains a lot of lines in the source code.">{this.state.workexpr5[value]}</p>


                                </div>

                              </div>


                            )
                          })
                        }
                      </edit>
                    </div>
                  </div>
                  <div className="col-md-12 pn" style={{ paddingTop: '1em' }}>
                    <div className="row rowt">
                      <div className="col-md-12 cl handle">  <i style={{ fontSize: "25px", position: 'absolute', right: "95%" }} className="fa fa-flask i handle" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <b ><span className="dflt dflt1 header t pre-line" style={{ color: `${this.state.color}` }} onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" onBlur={(e) => this.title(e, 5)} id={"projt"} placeholder="PROJECTS" >{(this.state.title ? this.state.title[5] : "PROJECTS")}</span>  </b>&nbsp;&nbsp;<i style={{ color: "green", fontSize: "20px", cursor: "pointer" }} onClick={this.addproject.bind(this)} className="fa fa-plus-circle i" id="ico11" data-html2canvas-ignore="true"
                      ></i>&nbsp;&nbsp;<i onClick={this.remproject.bind(this)} style={{ color: "red", fontSize: "20px", cursor: "pointer" }} className="fa fa-minus-circle i" id="ico12" data-html2canvas-ignore="true"
                      ></i>
                        <hr />  </div>
                    </div>
                    <div className="row">
                      <edit id="sortable5" className="col-md-12 left6">
                        {
                          this.state.projectname.map((value, key4) => {
                            return (
                              <div style={{ padding: "0% 0%" }} className="cl">
                                <b><span onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" id={"proj" + key4} onBlur={this.projectname.bind(this, key4)} className="dflt roles pre-line" style={{ textTransform: "capitalize", fontSize: '15px' }} placeholder="Project Name" >{this.state.projectname[key4]}</span></b><br />
                                <div style={{ width: '25%' }}>
                                  <DateRangePickerComponent start={this.start} depth={this.depth} onChange={this.projectdate.bind(this, key4)} id={"projdate" + key4} value={this.state.projectdate[key4]} format='MMMM yyyy' placeholder='MM-YYYY  MM-YYYY'></DateRangePickerComponent><br />
                                </div>
                                {/* <b>Short Summary</b> */}
                                <b><span onClick={(e) => this.focs(e)} id={"shorttitle" + key4} onBlur={this.shorttitle.bind(this, key4)} suppressContentEditableWarning={true} contentEditable="true" placeholder="Short Summary" className="dflt  dflt1 pre-line" style={{ textTransform: "capitalize", fontSize: '15px' }}>{this.state.pshorttitle[key4]}</span></b>
                                <br></br>
                                <div onClick={(e) => this.focs(e)} style={{ fontSize: '14px' }} suppressContentEditableWarning={true} contentEditable="true" id={"shortsum" + key4} onBlur={this.projectsum.bind(this, key4)} className="dflt pre-line" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua">{this.state.projectsum[key4]}</div>
                                <br />
                              </div>
                            );
                          })
                        }
                      </edit>
                    </div>



                  </div>
                  <div className="col-md-12 lg mytime-module" style={{ paddingTop: "1em" }}>
                    <div className="row rowt cl">
                      <div className="col-md-12 cl handle">
                        <i
                          style={{
                            fontSize: "25px",
                            position: "absolute"

                          }}
                          className="fa fa-briefcase i"
                          aria-hidden="true"
                        ></i>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <b>
                          <span className="dflt dflt1 header t pre-line" style={{ color: `${this.state.color}` }} onClick={(e) => this.focs(e)} suppressContentEditableWarning={true} contentEditable="true" onBlur={(e) => this.title(e, 7)} id={"timeline"} placeholder="MY TIMELINE">
                            MY TIME
                                </span>
                        </b>
                              &nbsp;&nbsp;
                              <i
                          style={{
                            color: "green",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          onClick={this.addToMyTime.bind(this)}
                          className="fa fa-plus-circle i"
                          id="ico7"
                        ></i>
                              &nbsp;&nbsp;
                              <i onClick={this.removeFromMyTime.bind(this)} style={{ fontSize: "20px", cursor: "pointer" }} id="ico8" className="fa fa-minus-circle i"></i>
                        <hr />{" "}
                      </div>
                    </div>
                    <div className="row cl">
                      <MyTimeline color={this.state.color} myTimeChartInformation={this.state.myTimeChartInformation} onChange={this.onMyTimeInformationChange.bind(this)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="pbt" id="generatePDFButton" onClick={this.generatePDF.bind(this)}>Generate PDF</button>
      </div>
    )




  }


}
